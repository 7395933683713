import { Route } from 'vue-router'
import { LOCALE_CODES } from '~/lang/locales'
import CATEGORIES from '../../json/catRoot.json'

const ROOT_SLUGS = Object.values(CATEGORIES)
ROOT_SLUGS.push(
  'campsites',
  'maps',
  'search',
  'glamping',
  'glamping2',
  'searchnew',
)

export function isSearchUrlRoute(
  route: Route,
  extraRoots: string[] = [],
  excludeRoots: string[] = [],
): boolean {
  return isSearchUrlComponent(route.path, extraRoots, excludeRoots)
}

export function isSearchUrlComponent(
  basePath: string,
  extraRoots: string[] = [],
  excludeRoots: string[] = [],
): boolean {
  const allRoots = [...ROOT_SLUGS, ...extraRoots].filter(
    (root) => !excludeRoots.includes(root),
  )
  const basePathNoPrefix = removeRootPrefix(
    removeLangPrefix(checkSlashes(basePath)),
    allRoots,
  )
  if (basePathNoPrefix === false) return false
  const [base, _facetUrl] = basePathNoPrefix.split('-/')
  // count base path segments
  const baseSegments = base.split('/').filter(Boolean)
  return baseSegments.length <= 4
}

// simple function to make sure the path always has slashes
function checkSlashes(path: string) {
  if (path.endsWith('/')) {
    path = path.slice(0, -1)
  }
  if (path.startsWith('/')) {
    path = path.slice(1)
  }
  return path ? `/${path}/` : '/'
}

function removeLangPrefix(target: string): string {
  return removePrefix(LOCALE_CODES, target)
}

function removeRootPrefix(target: string, allRoots: string[]) {
  const newUrl = removePrefix(allRoots, target)
  return newUrl === target ? false : newUrl
}

function removePrefix(prefixes: string[], target: string): string {
  // Escape special characters in prefixes and join them into a single regex pattern
  // The pattern is designed to match the prefix at the start and include any following content
  // const escapedPrefixes = prefixes.map(prefix => prefix.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'))
  const escapedPrefixes = prefixes
  const regexPattern = `^/(${escapedPrefixes.join('|')})/(.*)`
  const regex = new RegExp(regexPattern)
  // Replace the matched prefix with a slash, ensuring the resulting URL starts with a slash
  // and retains the rest of the path as is
  return target.replace(regex, '/$2')
}
